<template>
    <div>
        <h3>Add Signature</h3>
        <p>Use your mouse or touchpad to add your signature in the box below.</p>
        <VueSignaturePad
            class="signature-pad"
            ref="signature"
        />
        <div class="button-container">
            <button class="scratch-button secondary" @click="cancel">Cancel</button>
            <button class="scratch-button secondary" @click="clearSignature">Clear</button>
            <button class="scratch-button primary" @click="saveSignature">Save</button>
        </div>
    </div>
</template>

<script>
import { signatures } from '@/util/apiRequests';
import Vue from 'vue';
import VueSignaturePad from 'vue-signature-pad';

Vue.use(VueSignaturePad);

    export default {
        name: 'ScratchPad',
        props: {
            idType: {

            },
            idValue: {

            },
            type: {

            }
        },
        computed: {
            signaturePad() {
                return this.$refs.signature;
            },
        },
        data(){
            return {
                signatureInfo: {}
            }
        },

        methods: {
            clearSignature() {
                this.signaturePad.clearSignature();
            },
            async getSignature(){
                let result = await this.$api.get(signatures.getSignature(this.idValue), {params: {t: this.type}});
                if(result.data){
                    
                    this.signatureInfo = result.data
                    this.$nextTick(() => {
                    //don't initially set your signature unless there is a result, otherwise
                    //the isEmpty helper function won't work which makes things weird.
                    this.signaturePad.fromDataURL(this.signatureInfo.file?.Body ? `data:image/png;base64, ${this.signatureInfo?.file?.Body}`: undefined);
                    }, 500);
                }
            },
            
            async saveSignature() {
                const { data, isEmpty } = this.signaturePad.saveSignature();
                if (!isEmpty) {
                    let file = new FormData();
                    file.append("file", this.dataURIToBlob(data));
                    file.append("signature", data);
                    file.append("resourceId", this.idValue);
                    file.append("type", this.type)
                    file.append("fileId", this.signatureInfo?.file_id)
                    let result = await this.$api.post(signatures.saveSignature(), file);
                    this.signatureInfo.file_id = result.data.fileId;
                    this.signatureInfo.id = result.data.signId;
                    this.$emit('cancel');

                }
            },
            dataURIToBlob(dataURI) {
                    const splitDataURI = dataURI.split(',')
                    const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1])
                    const mimeString = splitDataURI[0].split(':')[1].split(';')[0]

                    const ia = new Uint8Array(byteString.length)
                    for (let i = 0; i < byteString.length; i++)
                        ia[i] = byteString.charCodeAt(i)

                    return new Blob([ia], { type: mimeString })
            },
            cancel(){
                this.$emit('cancel');
            }
        },
        async created(){
            await this.getSignature();
        },
        
    };
</script>

